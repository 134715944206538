import React, { Component } from 'react'
import Vatomic from '../Vatomic';
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "external-svg-loader";

export default class Resource extends Component {

    getFileName(url) {
        // return url.split("/")[url.split("/").length - 1].split(".")[0].split("-").join(" ").split("_").join(" ")
        return url.split("/")[url.split("/").length - 1]
    }

    render() {
        const url = this.props.url
        var encoded_url = Vatomic.encodeAssetProvider(url);
        console.log(encoded_url, encoded_url.indexOf(".glb") !== -1)
        return (
            <Tooltip key={url} title={this.getFileName(url)}>
                <CopyToClipboard text={url}>
                    {encoded_url.indexOf(".mp4") === -1 && encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".svg") === -1 && encoded_url.indexOf(".glb") === -1 ?
                        <img onDoubleClick={this.props.handleContextMenu || null} onContextMenu={this.props.handleContextMenu || null} src={encoded_url} style={{ width: this.props.width || "10%", margin: "2%", objectFit:'contain' }} />
                        : encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".svg") === -1 && encoded_url.indexOf(".glb") === -1 ?
                            <video onDoubleClick={this.props.handleContextMenu || null} onContextMenu={this.props.handleContextMenu || null} style={{ width: this.props.width || "10%", margin: "2%" }} controls>
                                <source src={encoded_url} type="video/mp4" />
                            </video>
                        : encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".svg") !== -1 ?
                            <svg
                                onDoubleClick={this.props.handleContextMenu || null}
                                data-src={encoded_url}
                                width={this.props.width || "10%"}
                                style={{ margin: "2%" }}
                            />
                        : encoded_url.indexOf(".glb") !== -1 ?
                        <model-viewer style={{ width: this.props.width || "10%", margin: "2%" }} src={encoded_url} alt="A 3D model" camera-controls></model-viewer>
                            : 
                            null}
                </CopyToClipboard>
            </Tooltip>
        )
    }
}
