import React, { Component } from 'react';
import '../../style/Login/Base.css'

export default class Base extends Component {

    render() {
        return <div className={"page base"}>
            <div className={"frame"}>
                <div className={"guide"}>
                    <img alt="" src={require('../../images/jalo_new.png')} />
                    <h1 className={"text"}>{this.props.message}</h1>
                    <h2 className={"text"}>{this.props.subMessage}</h2>
                </div>
                <div className={"card"}>{[this.props.showBack && <div key="div1" onClick={this.props.onBack} className={"back"}><img alt="back" src={require('../../images/chevron-left.svg')} /></div>, this.props.children]}</div>
            </div>
        </div>
    }

}