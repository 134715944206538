import { CircularProgress } from '@material-ui/core'
import React from 'react'

export default function Loading() {
    return (
        <div style={{position:'fixed', top:0, left:0, right:0, bottom:0, zIndex:99999, background:"rgba(0,0,0,0.5)", display:"flex", justifyContent:'center', alignItems:'center'}}>
            <CircularProgress style={{color:"#fff"}}/>
        </div>
    )
}
