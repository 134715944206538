import React from 'react';
import AppDrawer from './AppDrawer'
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Vatomic from '../../Vatomic';
import Select from 'react-select';
import CustomSelectTheme from '../../style/Login/CustomSelect.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Distribute extends React.Component {
    constructor() {
        super();
        this.state = {
            user: "",
            open: false,
            recipient:""
        }
    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }

    componentDidMount() {
        Vatomic.templateVariations().then(variations => {
            variations = variations.sort((variation1, variation2)=>{
                return (variation1.name > variation2.name ? -1 : variation1.name < variation2.name) * -1;
            })
            this.setState({
                variations: variations
            })
        })
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }
    handleDrawerOpen() {
        this.setState({ open: true });
    };

    handleDrawerClose() {
        this.setState({ open: false });
    };

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    handleSelectChange(newValue) {
        this.setState({ variation: newValue.value });
    }

    handleRecipientChange(event) {
        this.setState({ recipient: event.target.value })
    }

    send() {
        // console.log(this.state.recipient,this.state.variation)
        Vatomic.sendVatom(this.state.recipient, this.state.variation).then(result => {
            if(typeof(result)=='number'){
                this.setState({ sendResult: result})    
            }
            else{
                this.setState({ sendResult: result.payload.main.output })
            }
            
        })
    }

    handleDialogOK() {
        window.location.reload()
    }

    render() {
        const component = (
            <div>
                {this.state.variations ?
                    <Select
                        options={this.state.variations.map(variation => ({ value: variation.name, label: variation.name }))}
                        onChange={this.handleSelectChange.bind(this)}
                        className="custom-select"
                        placeholder="Type to search variation"
                    />
                    : null}

                {this.state.variation ?
                    <TextField
                        fullWidth
                        helperText="Phone number with '+' format or email"
                        label="Recipient"
                        value={this.state.recipient}
                        onChange={this.handleRecipientChange.bind(this)}
                        margin="normal"
                    />
                    : null}

                {this.state.recipient ?
                    <Button color="primary" className="btn-full" style={{ width: '100%', height: '48px', color: "#fff" }} variant="contained" onClick={this.send.bind(this)}>
                        {/* Enviar */}
                        <SendIcon style={{ marginLeft: 'auto' }} />
                    </Button>
                    : null}

                <Dialog
                    open={Boolean(this.state.sendResult)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Send Result"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.sendResult}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogOK.bind(this)} color="primary" autoFocus>
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
        return (
            <AppDrawer component={component}
                title="Distribute"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
            />
        )
    }

}