import React from 'react';
import AppDrawer from './AppDrawer';
import Vatomic from '../../Vatomic';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { FormControl } from '../../../node_modules/@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';



export default class Faces extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: "",
            open: false,
            template: { name: "" },
        }

    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }
    handleSelectChange(event) {
        Vatomic.listFaces(event.target.value.name).then(async res => {
            console.log(res)
            this.setState({ faces: res })
        })
        this.setState({ template: event.target.value });
    }

    componentDidMount() {
        Vatomic.templates().then(templates => {
            templates = templates.sort((template1, template2) => {
                return (template1.name > template2.name ? -1 : template1.name < template2.name) * -1;
            })
            this.setState({templates: templates})
        })
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }

    handleDrawerOpen() {
        this.setState({ open: true });
    }

    handleDrawerClose() {
        this.setState({ open: false });
    }

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    render() {
        const component = (
            <div style={{ textAlign: 'left', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                {this.state.templates ?
                    <FormControl style={{ width: '100%' }}>
                        <InputLabel htmlFor="template-selector">Template</InputLabel>
                        <Select
                            value={this.state.template.name}
                            onChange={this.handleSelectChange.bind(this)}
                            input={<Input id="template-selector" />}
                            renderValue={value => value}
                            style={{ width: '80%' }}
                        >
                            {this.state.templates.map(template => {
                                return <MenuItem key={template.name} value={template}>
                                    {template.name}
                                </MenuItem>
                            })}
                        </Select>
                    </FormControl> : null}
                {this.state.template.name !== "" ? <div><Divider style={{ marginTop: 20 }} /><p>Registered faces</p></div> : null}
                {this.state.faces ?
                    this.state.faces.map(face => {
                        return (
                            <ExpansionPanel key={face.id} style={{ marginTop: 20 }}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>{face.properties.constraints.platform + " " + face.properties.constraints.quality + " " + face.properties.constraints.view_mode}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelActions>
                                    <Button
                                        variant="fab"
                                        aria-label="Edit">
                                        <EditIcon />
                                    </Button>
                                    <Button
                                        variant="fab"
                                        aria-label="Save">
                                        <SaveIcon />
                                    </Button>
                                    <Button
                                        variant="fab"
                                        aria-label="Delete">
                                        <DeleteIcon />
                                    </Button>
                                </ExpansionPanelActions>
                                <ExpansionPanelDetails>
                                    {/* <Typography> */}
                                    <pre id={face.id + "_pre"}
                                    style={{ overflow: 'auto' }}>{JSON.stringify(face, undefined, 4)}</pre>
                                    {/* </Typography> */}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        )
                    })
                    : this.state.template.name !== "" ?
                        <div style={{ fontSize: 12, fontStyle: "italic" }}>No faces registered yet</div>
                        : null}
            </div>
        )
        return (
            <AppDrawer component={component}
                title="Register Faces"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
            />
        )

    }
}