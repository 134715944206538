import React from 'react';
import AppDrawer from './AppDrawer';
import Vatomic from '../../Vatomic';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormControl } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';




export default class Template extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ppropDialog: false,
            newProp: "",
            newResource: {
                "name": "",
                "resourceType": "",
                "value": {
                    "resourceValueType": "ResourceValueType::URI",
                    "value": ""
                }
            },
            resourceDialog: false,
            user: "",
            open: false,
            template: {
                "template": "",
                "public": false,
                "cloneable": false,
                "unpublished": true,
                "vatom": {
                    "vAtom::vAtomType": {
                        "root_type": "vAtom::vAtomType",
                        "redeemable": false,
                        "states": [
                            {
                                "name": "Activated",
                                "value": {
                                    "type": "boolean",
                                    "value": "true"
                                }
                            }
                        ],
                        "resources": [
                        ]
                    },
                    "private": {}
                }
            },
        }
    }

    handleNewPropChange(event) {
        this.setState({ newProp: event.target.value })
    }

    handleNewResourceChange(event) {
        var resource = this.state.newResource
        resource.name = event.target.value
        this.setState({ newResource: resource })
    }
    handlePropChange(event) {
        var template = this.state.template
        template.vatom["vAtom::vAtomType"].private[event.target.name] = event.target.value
        this.setState({ template: template })
    }

    handleSwitchChange(event) {
        var template = this.state.template
        var name = event.target.name
        if (name === "unpublished") {
            template[name] = !event.target.checked
        }
        else {
            template[name] = event.target.checked
        }
        this.setState({ template: template })
    }

    handleRedeemableChange(event) {
        var template = this.state.template
        template.vatom["vAtom::vAtomType"].redeemable = event.target.checked
        this.setState({ template: template })
    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }

    resourceTypeChange(event) {
        var resource = this.state.newResource
        resource.resourceType = event.target.value
        this.setState({ newResource: resource })
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }

    handleDrawerOpen() {
        this.setState({ open: true });
    }

    handleDrawerClose() {
        this.setState({ open: false });
    }

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    handleDialogClose() {
        this.setState({ ppropDialog: false })
    }

    handleResourceDialogClose() {
        this.setState({ resourceDialog: false })
    }

    handleAddPprop() {
        this.setState({ ppropDialog: true })
    }

    handleAddResource() {
        this.setState({ resourceDialog: true })
    }

    handleNewPprop() {
        var template = this.state.template
        var privateprops = template.vatom.private
        var pprop = this.state.newProp
        if (typeof (privateprops) === "undefined") {
            template.vatom.private = { [pprop]: "" }

        }
        else {
            privateprops[pprop] = ""
        }
        this.setState({ newProp: "", ppropDialog: false, template: template })
    }

    handleNewResource() {
        const resource = {
            "name": "",
            "resourceType": "",
            "value": {
                "resourceValueType": "ResourceValueType::URI",
                "value": ""
            }
        }
        var template = this.state.template
        const resources = template.vatom["vAtom::vAtomType"].resources.filter(res => { return res.name === this.state.newResource.name })
        if (resources.length === 0)
            template.vatom["vAtom::vAtomType"].resources.push(this.state.newResource)

        this.setState({ newResource: resource, resourceDialog: false })
    }

    deleteResource(resource) {
        const template = this.state.template
        const resources = template.vatom["vAtom::vAtomType"].resources.filter(res => { return res.name !== resource })
        template.vatom["vAtom::vAtomType"].resources = resources
        this.setState({ template: template })
    }

    deleteProp(pprop) {
        console.log(this.state.template.vatom.private)
        delete this.state.template.vatom.private[pprop]
        this.setState({ template: this.state.template })
    }

    render() {
        const component = (
            <div style={{ textAlign: 'left', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                <p style={{ marginBottom: 0 }}>General Properties</p>
                <TextField
                    name="name"
                    style={{ width: "100%" }}
                    label="name"
                    margin="none"
                    value={this.state.template.template}
                    InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                />
                <FormControlLabel
                    control={
                        <Switch
                            name="unpublished"
                            onChange={this.handleSwitchChange.bind(this)}
                            checked={!this.state.template.unpublished}
                            color="primary"
                        />
                    }
                    label="Published"
                />
                <FormControlLabel
                    control={
                        <Switch
                            name="public"
                            onChange={this.handleSwitchChange.bind(this)}
                            checked={this.state.template.public}
                            color="primary"
                        />
                    }
                    label="Public"
                />
                <FormControlLabel
                    control={
                        <Switch
                            name="cloneable"
                            onChange={this.handleSwitchChange.bind(this)}
                            checked={this.state.template.cloneable}
                            color="primary"
                        />
                    }
                    label="Clonable"
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={this.state.template.vatom["vAtom::vAtomType"].redeemable}
                            onChange={this.handleRedeemableChange.bind(this)}
                            color="primary"
                        />
                    }
                    label="Redeemable"
                />

                <Divider style={{ marginTop: 20 }} />
                <div style={{ position: "relative" }}>
                    <p style={{ marginBottom: 0 }}>Private Properties</p>
                    <IconButton size="small" color="primary" aria-label="Add"
                        style={{ position: "absolute", bottom: 0, right: 0, display: "block" }}
                        onClick={this.handleAddPprop.bind(this)}
                    >
                        <AddIcon />
                    </IconButton>
                    {this.state.template.vatom.private ? Object.keys(this.state.template.vatom.private).map(pprop => {
                        return (

                            <TextField
                                key={pprop}
                                name={pprop}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button onClick={this.deleteProp.bind(this, pprop)}>
                                                <DeleteIcon />
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                                style={{ marginRight: 10 }}
                                label={pprop}
                                margin="none"
                                InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                                onChange={this.handlePropChange.bind(this)}
                            />

                        )
                    })
                        : null}

                </div>

                <Divider style={{ marginTop: 20 }} />
                <div style={{ position: "relative" }}>
                    <p style={{ marginBottom: 0 }}>Resources</p>
                    <IconButton size="small" color="primary" aria-label="Add"
                        style={{ position: "absolute", bottom: 0, right: 0, display: "block" }}
                        onClick={this.handleAddResource.bind(this)}>
                        <AddIcon />
                    </IconButton>
                    {this.state.template.vatom["vAtom::vAtomType"].resources.map(resource => {
                        return (
                            <Chip
                                key={resource.name}
                                label={resource.name}
                                onDelete={this.deleteResource.bind(this, resource.name)}
                            />
                        )
                    })}
                </div>

                <Divider style={{ marginTop: 20 }} />

                <ExpansionPanel style={{ marginTop: 20 }}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Preview</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <pre style={{ overflow: 'auto' }}>{JSON.stringify(this.state.template, undefined, 4)}</pre>
                    </ExpansionPanelDetails>
                </ExpansionPanel>


                <Dialog
                    open={this.state.ppropDialog}
                    onClose={this.handleDialogClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Add new private property"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            style={{ marginRight: 10 }}
                            label={"Private Property"}
                            margin="none"
                            InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                            value={this.state.newProp}
                            onChange={this.handleNewPropChange.bind(this)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose.bind(this)} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={this.handleNewPprop.bind(this)} color="primary" autoFocus>
                            Add
                    </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.resourceDialog}
                    onClose={this.handleResourceDialogClose.bind(this)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Add new resource"}</DialogTitle>
                    <DialogContent>
                        <TextField
                            style={{ marginRight: 10 }}
                            label={"Resource Name"}
                            margin="none"
                            InputLabelProps={{ style: { position: 'relative', top: 24 } }}
                            value={this.state.newResource.name}
                            onChange={this.handleNewResourceChange.bind(this)}
                        />
                        <FormControl style={{ display: "block", }}>
                            <InputLabel htmlFor="resourceType">Resource Type</InputLabel>
                            <Select
                                style={{ width: "100%" }}
                                value={this.state.newResource.resourceType}
                                onChange={this.resourceTypeChange.bind(this)}
                                inputProps={{
                                    name: 'age',
                                    id: 'resourceType',
                                }}
                            >
                                <MenuItem value="ResourceType::Image::GIF">ResourceType::Image::GIF</MenuItem>
                                <MenuItem value="ResourceType::Image::PNG">ResourceType::Image::PNG</MenuItem>
                                <MenuItem value="ResourceType::Image::JPEG">ResourceType::Image::JPEG</MenuItem>
                                <MenuItem value="ResourceType::Text::Plain">ResourceType::Text::Plain</MenuItem>
                                <MenuItem value="ResourceType::Text::HTML">ResourceType::Text::HTML</MenuItem>
                                <MenuItem value="ResourceType::Code::Javascript">ResourceType::Code::Javascript</MenuItem>
                                <MenuItem value="ResourceType::Code::Golang">ResourceType::Code::Golang</MenuItem>
                                <MenuItem value="ResourceType::Audio::MPEG">ResourceType::Audio::MPEG</MenuItem>
                                <MenuItem value="ResourceType::Audio::WAV">ResourceType::Audio::WAV</MenuItem>
                                <MenuItem value="ResourceType::Video::AVI">ResourceType::Video::AVI</MenuItem>
                                <MenuItem value="ResourceType::Video::MPEG">ResourceType::Video::MPEG</MenuItem>
                                <MenuItem value="ResourceType::Video::Quicktime">ResourceType::Video::Quicktime</MenuItem>
                                <MenuItem value="ResourceType::Video::ASF">ResourceType::Video::ASF</MenuItem>
                                <MenuItem value="ResourceType::3D::Scene">ResourceType::3D::Scene</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleResourceDialogClose.bind(this)} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={this.handleNewResource.bind(this)} color="primary" autoFocus>
                            Add
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
        return (
            <AppDrawer component={component}
                title="Create new Template"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
            />
        )

    }
}