import React, { Component } from 'react';
import Base from './Base'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Vatomic from '../../Vatomic'
import FormTheme from '../../style/Login/Form.css'

export default class LoginPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: "",
            pass: ""
        }
    }
    componentWillMount() {
        if (typeof (this.props.location.state) !== 'undefined')
            this.setState({ user: this.props.location.state.user })
    }

    componentDidMount() {
        if (!this.state.user)
            this.props.history.replace("/")
    }

    onNext(e) {
        e.preventDefault();
        Vatomic.login(this.state.user, this.state.pass).then(result => {
            if (typeof (result) !== 'number') {
                var location = {
                    pathname: '/Templates',
                    state: { user: result.payload.user.properties.first_name || "Anónimo" }
                }
                this.props.history.push(location);
            }
            else {
                if (result === 17) {
                    this.setState({ error: "Not a publisher" })
                }
                else {
                    this.setState({ error: "Error" })
                }
            }
        })
    }

    handlePassChange(event) {
        this.setState({ pass: event.target.value });
        if (event.target.value.length === 0) {
            this.setState({ error: "Required field" });
        }
        else {
            this.setState({ error: "" });
        }
    }
    onBack() {
        this.props.history.goBack()
    }
    render() {
        return <Base message="Publisher Portal" subMessage="" onBack={this.onBack.bind(this)} showBack>

            <div className={"form center"}>

                <div>
                    <div className={"image-holder"}></div>
                    <span className={"form text"}>Enter password</span>
                    <span className={"form text"}>{this.state.user}</span>

                </div>
                <form onSubmit={this.onNext.bind(this)}>
                    <div>
                        <TextField autoCapitalize="off" type='password' label="Password"
                            onChange={this.handlePassChange.bind(this)}
                            value={this.state.pass}
                            error={this.state.error ? true : false}
                            helperText={this.state.error}
                            autoFocus={true}
                            margin='normal'
                            style={{ width: '100%' }}
                        />
                    </div>
                    <Button className="btn-full" style={{ width: '100%', height: '48px', color: "#fff" }} onClick={this.onNext.bind(this)}>Next</Button>
                </form>
            </div>

        </Base>

    }



}