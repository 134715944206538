import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Vatomic from '../../Vatomic';

export default class Resources extends React.Component {
    constructor() {
        super();
        this.state = {
            user: "",
            open: false
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        Vatomic.assets().then(result => {
            if (typeof (this.props.resourceType) !== "undefined") {

                if (this.props.resourceType.toLowerCase() === 'jpeg') {
                    var re = new RegExp("(.*" + this.props.resourceType.toLowerCase() + "|.*jpg)", 'g')
                }
                else {
                    var re = new RegExp(".*" + this.props.resourceType.toLowerCase(), 'g')
                }
                result.urls = result.urls.filter(url =>
                    url.match(re)
                )

            }
            this.setState({
                assets: result
            })
        })


    }
    handleSelectChange(event) {
        this.setState({ template: event.target.value });
    }

    handleAdd() {
        this.refs.file_select.click();
    }

    handleSearchChange(event) {
        var search = event.target.value
        this.setState({ search: search })
    }

    handleSearch() {
        var assets = Object.assign({}, this.state.assets)
        const search = this.state.search
        if (search !== "") {
            this.setState({ loading: true })
            var search_query = ""
            search.split(" ").map(search_term => {
                search_query += ".*(?=.*" + search_term + ".*)"
            })
            search_query += ".*"
            var re = new RegExp(search_query, 'g')
            assets.urls = assets.urls.filter(url => url.toLowerCase().match(re))
            this.setState({ filtered_assets: assets, loading: false })
        }
    }

    handleFileChange(event) {
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];
        Vatomic.uploadAsset(file).then(result => {
            window.location.reload();
        })
    }

    render() {
        return (
            <div id="overlay" onClick={this.props.onClose} style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, zIndex: 9999, background: "rgba(0,0,0,0.8)", alignItems: 'center' }}>
                <div style={{ position: 'absolute', maxWidth: 800, maxHeight: 500, width: '80%', height: '80%', background: "#fff", padding: 50, overflowX: 'scroll', margin: 'auto', top: 0, bottom: 0, left: 0, right: 0 }}>
                    <div>
                        <TextField
                            id="name"
                            label="Search"
                            value={this.state.search}
                            onChange={this.handleSearchChange.bind(this)}
                            margin="normal"
                        />
                        <Button onClick={this.handleSearch.bind(this)}>
                            Go
                        </Button>
                    </div>
                    {this.state.filtered_assets ?
                        this.state.filtered_assets.urls.map(url => {
                            var encoded_url = Vatomic.encodeAssetProvider(url);
                            return (
                                encoded_url.indexOf(".mp4") === -1 && encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".glb") === -1 ?

                                    <Tooltip key={url} title={url}>
                                        <img alt={url} onClick={this.props.handleClick} name={url} src={encoded_url} style={{ width: 150, margin: 20 }} />
                                    </Tooltip>

                                    :
                                    encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".glb") === -1 ?

                                        <Tooltip key={url} title={url}>
                                            <video style={{ width: 150, margin: 20 }} controls>
                                                <source src={encoded_url} type="video/mp4" />
                                            </video>
                                        </Tooltip>

                                        :
                                        encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".glb") !== -1 ?
                                            <Tooltip key={url} title={url}>
                                                <model-viewer style={{ display:'inline-block',width: 150, margin: 20 }} src={encoded_url} alt="A 3D model" camera-controls></model-viewer>
                                            </Tooltip>
                                            : null
                            )
                        }) :
                        this.state.assets ?
                            this.state.assets.urls.map(url => {
                                var encoded_url = Vatomic.encodeAssetProvider(url);
                                return (
                                    encoded_url.indexOf(".mp4") === -1 && encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".glb") === -1 ?

                                        <Tooltip key={url} title={url}>
                                            <img alt={url} onClick={this.props.handleClick} name={url} src={encoded_url} style={{ width: 150, margin: 20 }} />
                                        </Tooltip>

                                        :
                                        encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".glb") === -1 ?

                                            <Tooltip key={url} title={url}>
                                                <video style={{ width: 150, margin: 20 }} controls>
                                                    <source src={encoded_url} type="video/mp4" />
                                                </video>
                                            </Tooltip>

                                            :
                                            encoded_url.indexOf(localStorage.getItem('pub_fqdn')) !== -1 && encoded_url.indexOf(".glb") !== -1 ?
                                                <Tooltip key={url} title={url}>
                                                    <model-viewer style={{ display:'inline-block',width: 150, margin: 20 }} src={encoded_url} alt="A 3D model" camera-controls></model-viewer>
                                                </Tooltip>
                                                : null
                                )
                            }) : null
                    }
                    <Button onClick={this.handleAdd.bind(this)} variant="fab" color="primary" aria-label="Add" style={{ position: 'fixed', bottom: 30, right: 30 }}>
                        <AddIcon />
                        <input ref="file_select" type="file" style={{ display: 'none' }} onChange={this.handleFileChange.bind(this)} />
                    </Button>
                </div>
            </div>
        )
    }

}