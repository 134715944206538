import React from 'react';
import AppDrawer from './AppDrawer'
import PinDropIcon from '@material-ui/icons/PinDrop';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Vatomic from '../../Vatomic';
import Select from 'react-select';
import CustomSelectTheme from '../../style/Login/CustomSelect.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactMapGL, {Marker} from 'react-map-gl';
import RoomIcon from '@material-ui/icons/Room';

export default class Drop extends React.Component {
    constructor() {
        super();
        this.state = {
            user: "",
            open: false,
            coords: "",
            viewport: {
                width: "100%",
                height: 400,
                latitude: 37.7577,
                longitude: -122.4376,
                zoom: 8
            }
        }
    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }

    componentDidMount() {
        Vatomic.templateVariations().then(variations => {
            variations = variations.sort((variation1, variation2) => {
                return (variation1.name > variation2.name ? -1 : variation1.name < variation2.name) * -1;
            })
            this.setState({
                variations: variations
            })
        })
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }
    handleDrawerOpen() {
        this.setState({ open: true });
    };

    handleDrawerClose() {
        this.setState({ open: false });
    };

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    handleSelectChange(newValue) {
        this.setState({ variation: newValue.value });
    }

    handleCoordsChange(event) {
        this.setState({ coords: event.target.value })
    }

    handleCoordsDone() {
        const viewport = this.state.viewport;
        const coords = this.state.coords.replace(" ", "");
        const lat = parseFloat(coords.split(",")[0])
        const long = parseFloat(coords.split(",")[1])
        viewport.latitude = lat
        viewport.longitude = long
        viewport.zoom = 13
        this.setState({ coordsDone: true, viewport, lat, long })

    }

    markerDragged(event){
        this.setState({lat:event.lngLat[1], long: event.lngLat[0], coords:event.lngLat[1] + "," + event.lngLat[0]})
    }

    send() {
        // console.log(this.state.recipient,this.state.variation)
        Vatomic.dropVatom(this.state.variation, this.state.coords).then(result => {
            if (typeof (result) == 'number' || result === "Not Valid coords") {
                this.setState({ sendResult: result })
            }
            else {
                this.setState({ sendResult: result.payload.main.output })
            }

        })
    }

    handleDialogOK() {
        window.location.reload()
    }

    render() {
        const component = (
            <div>
                {this.state.variations ?
                    <Select
                        options={this.state.variations.map(variation => ({ value: variation.name, label: variation.name }))}
                        onChange={this.handleSelectChange.bind(this)}
                        className="custom-select"
                        placeholder="Type to search variation"
                    />
                    : null}

                {this.state.variation ?
                    <TextField
                        fullWidth
                        helperText="Coords in lat,long format"
                        label="Coords"
                        value={this.state.coords}
                        onChange={this.handleCoordsChange.bind(this)}
                        margin="normal"
                    />
                    : null}

                {this.state.coords ?
                    <Button color="primary" className="btn-full" style={{ width: '100%', height: '48px', color: "#fff" }} variant="contained" onClick={this.handleCoordsDone.bind(this)}>
                        {/* Enviar */}
                        Preview
                    </Button>
                    : null}
                {this.state.coordsDone ?
                    <div>
                        <ReactMapGL
                            {...this.state.viewport}
                            mapboxApiAccessToken="pk.eyJ1IjoidnRpY2tldG14IiwiYSI6ImNrMnYxb2U1NjAwZ2gzbnFpb2JyOHJneTcifQ.Itm6EdxEeKgweb_USj5L4w"
                            onViewportChange={(viewport) => this.setState({ viewport })}>
                            <Marker 
                            offsetLeft={-20}
                            offsetTop={-40}
                            latitude={this.state.lat}
                            longitude={this.state.long}
                            draggable
                            onDragEnd={this.markerDragged.bind(this)}
                            >
                                <RoomIcon style={{color: "red", fontSize:40}}/>
                            </Marker>
                        </ReactMapGL>

                        <Button color="primary" className="btn-full" style={{ width: '100%', height: '48px', color: "#fff" }} variant="contained" onClick={this.send.bind(this)}>
                            {/* Enviar */}
                            <PinDropIcon style={{ marginLeft: 'auto' }} />
                        </Button>
                    </div>
                    : null}

                <Dialog
                    open={Boolean(this.state.sendResult)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Send Result"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.sendResult}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogOK.bind(this)} color="primary" autoFocus>
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
        return (
            <AppDrawer component={component}
                title="Drop"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
            />
        )
    }

}