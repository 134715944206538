import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';


const drawerWidth = 240;

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%'
    },
    appFrame: {
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        width: '100%',
    },
    appBar: {
        position: 'absolute',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'appBarShift-left': {
        marginLeft: drawerWidth,
    },
    'appBarShift-right': {
        marginRight: drawerWidth,
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 20,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        width: drawerWidth
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        overflowY: 'auto',
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    'content-left': {
        marginLeft: -drawerWidth,
    },
    'content-right': {
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    'contentShift-left': {
        marginLeft: 0,
    },
    'contentShift-right': {
        marginRight: 0,
    },
});




class AppDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
        }
    }


    render() {
        const { classes } = this.props;
        const open = this.props.open;
        const anchor = this.state.anchor;

        const drawer = (
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={this.props.handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Templates">Create new Variation</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Template">Create new Template</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Actions">Register Actions</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Faces">Register Faces</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Variations">Lookup Variations</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Resources">Lookup Resources</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Distribute">Distribute</List>
                <Divider />
                <List style={{ padding: '15px', cursor: 'pointer' }} onClick={this.props.handleDrawerClick} id="Drop">Drop</List>
                <Divider />
                <List style={{ cursor: 'pointer', color: 'red', position: 'absolute', bottom: 0, padding: 15 }} onClick={this.props.logout}>Logout</List>
            </Drawer>
        );
        let before = drawer;

        return (
            <div className={classes.root}>
                <div className={classes.appFrame}>
                    <AppBar
                        className={classNames(classes.appBar, {
                            [classes.appBarShift]: open,
                            [classes[`appBarShift-${anchor}`]]: open,
                        })}
                    >
                        <Toolbar disableGutters={!open}>
                            <IconButton
                                color="inherit"
                                aria-label="Open drawer"
                                onClick={this.props.handleDrawerOpen}
                                className={classNames(classes.menuButton, open && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Typography variant="title" color="inherit" noWrap>
                                {this.props.title}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {open ? before : null}

                    <main
                        className={classNames(classes.content, classes[`content-${anchor}`], {
                            [classes.contentShift]: open,
                            [classes[`contentShift-${anchor}`]]: open,
                        })}
                        style={this.props.style}
                    >
                        <div className={classes.drawerHeader} />

                        {this.props.component}

                    </main>
                </div>
            </div>




        )
    }


}


AppDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(AppDrawer);