import React from 'react';
import AppDrawer from './AppDrawer'
import MenuItem from '@material-ui/core/MenuItem';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Vatomic from '../../Vatomic';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { FormControl } from '../../../node_modules/@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class Variations extends React.Component {
    constructor() {
        super();
        this.state = {
            user: "",
            open: false,
            template: { name: "" },
            changes: {},
            editable:"",
            editDialog: false,
            editResult:""
        }
        this.handleEdit = this.handleEdit.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }

    componentDidMount() {
        Vatomic.templates().then(templates => {
            templates = templates.sort((template1, template2)=>{
                return (template1.name > template2.name ? -1 : template1.name < template2.name) * -1;
            })
            this.setState({
                templates: templates
            })
        })
        Vatomic.templateVariations().then(variations => {
            variations = variations.sort((variation1, variation2)=>{
                return (variation1.name > variation2.name ? -1 : variation1.name < variation2.name) * -1;
            })
            this.setState({
                variations: variations
            })
        })

    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }
    handleDrawerOpen() {
        this.setState({ open: true });
    };

    handleDrawerClose() {
        this.setState({ open: false });
    };

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    handlePpropChange(event) {
        var pprop = event.target.name;
        var template = this.state.template;
        var changes = this.state.changes;
        changes[pprop] = true
        template.properties.template.private[pprop] = event.target.value;
        this.setState(template);
        this.setState(changes);
    }

    handleSelectChange(event) {
        this.setState({ template: event.target.value });
    }

    handleEdit(variation){
        this.setState({editable:variation})
    }
    
    handleSave(payload){
        this.setState({editPayload:JSON.parse(payload)})
        this.setState({editDialog:true})
    }

    handlePatch(){
        
        Vatomic.updateTemplateVariation(this.state.editPayload).then(result=>{
            console.log(result)
            this.setState({editResult:result.success_message, editDialog:false})
        })
    }

    handlePatchClose(){
        this.setState({editDialog:false})
    }

    render() {
        const component = (
            <div>
                {this.state.templates ?
                    <div style={{ textAlign: 'center', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel htmlFor="template-selector">Template</InputLabel>
                            <Select
                                value={this.state.template.name}
                                onChange={this.handleSelectChange.bind(this)}
                                input={<Input id="template-selector" />}
                                renderValue={value => value}
                                style={{ width: '80%' }}
                            >
                                {this.state.templates.map(template => {
                                    return <MenuItem key={template.name} value={template}>
                                        {template.name}
                                    </MenuItem>
                                })}
                            </Select>
                        </FormControl></div> : null}

                {this.state.template.properties ?
                    <div style={{ textAlign: 'left', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto' }}>
                        {this.state.variations.map(variation => {
                            if (variation.properties.template === this.state.template.name) {
                                var new_variation = Vatomic.encodeTemplateVariation(variation)
                                return (
                                    <ExpansionPanel key={variation.name} style={{ marginTop: 20 }}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                            <Typography>{variation.name}</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelActions>
                                            <Button 
                                            variant="fab" 
                                            aria-label="Edit"
                                            onClick={() => this.handleEdit(variation.name)}
                                            disabled={this.state.editable === variation.name  || !variation.properties.unpublished}>
                                                <EditIcon />
                                            </Button>
                                            <Button 
                                            variant="fab" 
                                            aria-label="Edit"
                                            onClick={() => this.handleSave(document.getElementById(variation.name + "_pre").innerHTML)}
                                            disabled={this.state.editable !== variation.name}>
                                                <SaveIcon />
                                            </Button>
                                        </ExpansionPanelActions>
                                        <ExpansionPanelDetails>
                                            {/* <Typography> */}
                                            <pre id = {variation.name + "_pre"} disabled={this.state.editable !== variation.name} contentEditable={this.state.editable === variation.name} style={{ overflow: 'auto' }}>{JSON.stringify(new_variation, undefined, 4)}</pre>
                                            {/* </Typography> */}
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                )
                            }
                            else{
                                return null
                            }
                        })}
                    </div> : null}
                    <Dialog
                    open={this.state.editDialog}
                    onClose={this.handlePatchClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    >
                    <DialogTitle id="alert-dialog-title">{"Sure to edit?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to edit this variation?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handlePatchClose.bind(this)} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={this.handlePatch.bind(this)} color="primary" autoFocus>
                            Yes, I'm Sure
                    </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={Boolean(this.state.editResult)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Patch Result"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.editResult}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>window.location.reload()} color="primary" autoFocus>
                            Ok
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
        return (
            <AppDrawer component={component}
                title="Lookup Variations"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
            />
        )
    }

}