import React from 'react';
import AppDrawer from './AppDrawer'
import Tooltip from '@material-ui/core/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Menu from '@material-ui/core/Menu';
import Vatomic from '../../Vatomic';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ReactDropzone from "react-dropzone";
import Resource from '../../Components/Resource';
import Loading from '../../Components/Loading';
import { CircularProgress } from '@material-ui/core';

export default class Resources extends React.Component {
    constructor() {
        super();
        this.state = {
            user: "",
            open: false,
            menu_target: null,
            deleteDialog: false,
            search: ""
        }
        this.onDrop = this.onDrop.bind(this)
    }

    componentWillMount() {
        if (!Vatomic.isLoggedin()) {
            this.props.history.push('/');
        }
        if (typeof (this.props.location.state) !== 'undefined') {
            this.setState({ user: this.props.location.state.user })
            this.setState({ open: this.props.location.state.open || false })
        }
    }

    componentDidMount() {
        Vatomic.assets().then(result => {
            if (result) {
                result.urls = result.urls.sort()
                this.setState({
                    assets: result
                })
            }
        })
        this.dropZone = React.createRef()
    }

    logout() {
        localStorage.clear();
        this.props.history.push("/")
    }
    handleDrawerOpen() {
        this.setState({ open: true });
    };

    handleDrawerClose() {
        this.setState({ open: false });
    };

    handleDrawerClick(event) {
        var location = {
            pathname: '/' + event.target.id,
            state: { open: this.state.open }
        }
        this.props.history.push(location);
    }

    handlePpropChange(event) {
        var pprop = event.target.name;
        var template = this.state.template;
        var changes = this.state.changes;
        changes[pprop] = true
        template.properties.template.private[pprop] = event.target.value;
        this.setState(template);
        this.setState(changes);
    }

    handleSelectChange(event) {
        this.setState({ template: event.target.value });
    }

    handleAdd() {
        this.refs.file_select.click();
    }

    handleSearchChange(event) {
        var search = event.target.value.toLowerCase()
        this.setState({ search: search })
    }

    handleSearch() {
        var assets = Object.assign({}, this.state.assets)
        const search = this.state.search
        if (search !== "") {
            this.setState({ loading: true })
            var search_query = ""
            search.split(" ").map(search_term => {
                search_query += ".*(?=.*" + search_term + ".*)"
            })
            search_query += ".*"
            var re = new RegExp(search_query, 'g')
            assets.urls = assets.urls.filter(url => url.toLowerCase().match(re))
            this.setState({ filtered_assets: assets, loading: false })
        }
    }

    async handleFileChange(event) {
        event.stopPropagation();
        event.preventDefault();
        await Promise.all(Array.from(event.target.files).map(async file => {
            await Vatomic.uploadAsset(file);
        }))
        window.location.reload();
    }

    async onDrop(files) {
        this.setState({loading:true})
        await Promise.all(files.map(async file => {
            await Vatomic.uploadAsset(file);
        }))
        window.location.reload();
    }

    handleContextMenu(event) {
        event.preventDefault()
        event.stopPropagation()
        this.setState({ menu_target: event.target })
    }

    handleContextMenuClose() {
        this.setState({ menu_target: null })
    }

    handleDeleteOpen(event) {
        this.setState({ deleteDialog: true })
    }

    handleDelete() {
        Vatomic.deleteAsset(this.state.menu_target.title).then(result => {
            if (typeof (result) !== 'number') {
                window.location.reload()
            }
        })
    }

    handleDeleteClose() {
        this.setState({ deleteDialog: false })
        this.handleContextMenuClose()
    }

    render() {
        const component = (
            <div style={{display:'flex', flexDirection:'column', paddingTop:64}}>
                <div>
                    <TextField
                        id="name"
                        label="Search"
                        value={this.state.search}
                        onChange={this.handleSearchChange.bind(this)}
                        margin="normal"
                    />
                    <Button onClick={this.handleSearch.bind(this)}>
                        Go
                    </Button>
                </div>
                <ReactDropzone
                    onDrop={(files) => { this.onDrop(files) }}
                    accept="image/*, video/*, .glb"
                    ref={this.dropZone}
                    noClick
                >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div style={{ display: "flex", flexWrap: "wrap", position: 'relative', background: !isDragActive ? 'transparent' : "rgba(0,0,0,0.5)", flex:1, overflow:"auto" }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            {this.state.loading && <Loading />}
                                {this.state.filtered_assets ?
                                    this.state.filtered_assets.urls.map(url => {
                                        return (
                                            <Resource key={url} url={url} handleContextMenu={this.handleContextMenu.bind(this)} />
                                        )
                                    }) :
                                    this.state.assets ?
                                        this.state.assets.urls.map(url => {
                                            return (
                                                <Resource key={url} url={url} handleContextMenu={this.handleContextMenu.bind(this)} />
                                            )
                                        }) : null
                            }
                            <Button variant="fab" color="primary" aria-label="Add" style={{ position: 'fixed', bottom: 30, right: 30 }} onClick={() => {this.dropZone.current.open() }}>
                                <AddIcon/>
                            </Button>
                        </div>
                    )}
                </ReactDropzone>

                <Menu
                    id="simple-menu"
                    anchorEl={this.state.menu_target}
                    open={Boolean(this.state.menu_target)}
                    onClose={this.handleContextMenuClose.bind(this)}
                >
                    <MenuItem onClick={this.handleDeleteOpen.bind(this)}>Delete</MenuItem>
                </Menu>

                <Dialog
                    open={this.state.deleteDialog}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Sure to delete?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete this asset?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDeleteClose.bind(this)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleDelete.bind(this)} color="primary" autoFocus>
                            Yes, I'm Sure
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
        return (
            <AppDrawer component={component}
                title="Resources"
                logout={this.logout.bind(this)}
                history={this.props.history}
                location={this.props.location}
                handleDrawerClick={this.handleDrawerClick.bind(this)}
                handleDrawerClose={this.handleDrawerClose.bind(this)}
                handleDrawerOpen={this.handleDrawerOpen.bind(this)}
                open={this.state.open}
                style={{display:"flex", overflow:'hidden'}}
            />
        )
    }

}