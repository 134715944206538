import React, { Component } from 'react';
import Base from './Base'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormTheme from '../../style/Login/Form.css'
import Vatomic from '../../Vatomic';


export default class LoginName extends Component {
    constructor() {
        super();

        this.state = {
            user: ""
        }
    }

    componentWillMount() {
        if (Vatomic.isLoggedin()) {
            var location = {
                pathname: '/Templates',
                state: { user: Vatomic.parseJwt(localStorage.getItem('refresh_token')).user_id }
            }
            this.props.history.push(location);
        }
    }


    onNext(e) {
        e.preventDefault();
        var location = {
            pathname: '/login/password/',
            state: { user: this.state.user }
        }
        this.props.history.push(location);
    }

    handleUserChange(event) {
        this.setState({ user: event.target.value });
        if (event.target.value.length === 0) {
            this.setState({ error: "Required field" });
        }
        else {
            this.setState({ error: "" });
        }
    }
    render() {
        const primary = "##000";
        return <Base message="Publisher Portal" subMessage="">

            <div className={"form center"}>

                <div>
                    <div className={"image-holder"}></div>
                    <span className={"form text"}>Enter email to continue</span>
                </div>
                <form onSubmit={this.onNext.bind(this)}>
                    <div>
                        <TextField className="inputText" margin="normal" type='email' label="Email"
                            onChange={this.handleUserChange.bind(this)}
                            value={this.state.user}
                            error={this.state.error ? true : false}
                            helperText={this.state.error}
                            autoFocus={true}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <Button className="btn-full" style={{ width: '100%', height: '48px', color: "#fff" }} onClick={this.onNext.bind(this)}>Next</Button>
                </form>
            </div>

        </Base>

    }



}