import React, { Component } from 'react';
import './App.css';
import Templates from './Routes/Home/Templates';
import Variations from './Routes/Home/Variations';
import Resources from './Routes/Home/Resources';
import Distribute from './Routes/Home/Distribute';
import Drop from './Routes/Home/Drop';
import Template from './Routes/Home/Template';
import Login from './Routes/Login/Login'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LoginPassword from './Routes/Login/LoginPassword';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Actions from './Routes/Home/Actions';
import Faces from './Routes/Home/Faces';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#000',
      main: '#000',
      dark: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#7a3d7d',
      Light: '#7a3d7d',
      dark: '#7a3d7d',
      contrastText: '#fff',
    },
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Router >
          <Switch>
            <Route path="/Templates" component={Templates} />
            <Route path="/Template" component={Template} />
            <Route path="/Resources" component={Resources} />
            <Route path="/Variations" component={Variations} />
            <Route path="/login/password/" component={LoginPassword} />
            <Route path="/Distribute" component={Distribute} />
            <Route path="/Actions" component={Actions} />
            <Route path="/Faces" component={Faces} />
            <Route path="/Drop" component={Drop} />
            <Route path="/" component={Login} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
